@import './variables.scss';

.process {
    padding: 0 100px;

    @include bp($md) {
        padding: 0 50px;
    }

    &_steps {
        display: flex;
        align-items: center;
        gap: 35px;

        @include bp($lg) {
            align-items: center;
            flex-direction: column-reverse;
        }

        & img {
            width: 25%;
            height: 100%;

            @include bp($lg) {
                width: 40%;
                transform: rotate(90deg) translateX(-30%);
                border-radius: 15px;
            }

            @include bp($md) {
                display: none;
            }
        }

        & h3 {
            text-align: center;
        }

        &_step {
            margin: 30px 0;
            border-radius: 15px;
            padding: 20px;
            background-color: $beige;

            & b {
                color: $cta-color;
            }
        }
    }

    &_cta {
        width: fit-content;
        margin: 130px auto 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        font-size: 38px;

        @include bp($lg) {
            margin: 0 auto;
        }

        @include bp($md) {
            text-align: center;
            margin: 90px auto;
            font-size: 24px;
        }

        a {
            text-decoration: none;
            font-weight: bold;
            font-size: 28px;
            border-radius: 15px;
            padding: 10px 30px;
            margin: 15px 0;
            font-size: 45px;
            color: $white;
            background-color: $primary-color;

            @include bp($sm) {
                font-size: 24px;
            }

        }
    }

    @include bp($md) {
        padding: 0 40px;
    }
}