@import './variables.scss';
@import './fonts.css';

.header {
    background-color: $primary-color;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    transition: all 0.5s ease-in;

    @include bp($md) {
        position: fixed;
        top: -60%;
        right: -50%;
        width: 80%;
        height: 95%;
        z-index: 99;
        border-radius: 50%;
        padding: 180px 300px 150px 100px;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column;
    }

    @include bp($sm) {
        font-size: 24px;
        gap: 15px;
        top: -25%;
        right: -50%;
        width: 90%;
        height: 70%;
        padding: 50px 130px 150px 60px;
    }

    & a {
        text-decoration: none;
        color: $secondary-color;

        @include bp($md) {
            padding-left: 80px;
        }

        @include bp($sm) {
            padding-left: 0;
        }
    }

    &.sticky {
        position: sticky;
        top: 0;
        z-index: 1000;
    }

    &_logo {
        @include flex;
        gap: 15px;

        @include bp($md) {
            padding-left: 90px;
        }

        @include bp($sm) {
            padding-left: 60px;
        }

        & svg {
            width: 50px;
            height: auto;

            & g {
                fill: $secondary-color;
            }
        }

        &-name {
            font-family: $Brown;
            font-weight: bold
        }
    }

    & ul {
        @include flex;
        justify-content: space-around;
        list-style: none;
        width: 80%;
        color: $secondary-color;

        @include bp($md) {
            align-items: flex-start;
            flex-direction: column;
            font-size: 42px;
            gap: 20px;
        }

        @include bp($sm) {
            font-size: 24px;
            gap: 15px;
        }

        & li {
            cursor: pointer;
        }
    }
}

.ham {
    position: fixed;
    right: 30px;
    top: 30px;
    @include flex;
    flex-direction: column;
    gap: 10px;
    background-color: rgba($color: $light-green, $alpha: .3);
    padding: 15px 18px;
    width: fit-content;
    border-radius: 50%;
    z-index: 999;

    @include bp($sm) {
        padding: 10px 8px;
        gap: 5px;
        right: 15px;
        top: 20px;
    }

    & div {
        background-color: $cta-color;
        width: 35px;
        height: 5px;
        transition: all 0.3s ease-in-out;

        @include bp($sm) {
            width: 25px;
            height: 3px;
        }
    }

    &.active {
        & div:nth-child(1) {
            transform: rotate(45deg) translate(10px, 12px);

            @include bp($sm) {
                transform: rotate(45deg) translate(5px, 4px);
            }
        }

        & div:nth-child(2) {
            opacity: 0;
        }

        & div:nth-child(3) {
            transform: rotate(-45deg) translate(10px, -10px);

            @include bp($sm) {
                transform: rotate(-45deg) translate(7px, -7px);
            }
        }
    }
}