// Colors
$primary-color: #92A28E;
$secondary-color: #F6F6EA;
$cta-color: #a38b93;
$cta-color-3: #395c91;
$light-green: #ccd2ae;
$dark-green:#5c7057;
$beige: #eeddc9;
$white: #fcfbf9;
$gray: #646464;
$black: #333333;

//  Fonts
$Lit: 'Literaturnaya', 'Times New Roman', 'Garamond', 'Georgia', 'Merriweather', 'Playfair Display', 'Baskerville', 'Bookman Old Style', 'Century Schoolbook', serif;
$Brown: 'Brown Sugar', 'Segoe Script', 'Segoe UI', 'Tahoma', 'Verdana', 'Courier New', cursive;

// Breakpoints
$sm: 430px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1440px;

// Mixins
@mixin bp($max-width) {
    @media (max-width: $max-width) {
        @content;
    }
}

@mixin flex {
    display: flex;
    justify-content: center;
    align-items: center;
}