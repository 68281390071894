@import './variables.scss';

.contact {
    @include flex;
    gap: 50px;
    padding: 30px 0;
    margin-bottom: 50px;
    filter: grayscale(100%) sepia(100%) hue-rotate(55deg) saturate(70%) brightness(100%);


    @include bp($lg) {
        padding: 30px 0 60px;
    }

    @include bp($md) {
        padding: 0;
        margin-top: 100px;
    }

    &_social-media {
        width: 50px;

        @include bp($md) {
            width: 40px;
        }

        & img {
            width: 100%;
        }
    }
}