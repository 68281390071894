@import './variables.scss';

.services {

    & ul {
        @include flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 50px;
        width: 100%;
        list-style: none;
        padding: 0;

        @include bp($xxl) {
            gap: 30px;
        }
    }

    &_service {
        background-color: $beige;
        border-radius: 25px;
        padding: 30px;
        width: 18%;

        &-title {
            @include bp($md) {
                text-align: center;
            }
        }

        & b {
            color: $cta-color;
        }

        @include bp($xl) {
            width: 35%;
        }

        @include bp($xl) {
            width: 75%;
            padding: 20px;
        }
    }
}